// React vendors
@import '~socicon/css/socicon.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import '~prism-themes/themes/prism-shades-of-purple.css';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '~animate.css/animate.css';
@import 'react-quill/dist/quill.snow.css';
@import 'react-datepicker/dist/react-datepicker.css';
@import '~flatpickr/dist/flatpickr.css';
@import 'react-phone-input-2/lib/bootstrap.css';
// @import '~react-easy-crop/react-easy-crop.css';
@import 'react-calendar/dist/Calendar.css';
@import '@szhsin/react-menu/dist/index.css';
@import '@szhsin/react-menu/dist/transitions/zoom.css';

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-inline {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.attendance-date-picker {
  .react-calendar {
    border: white;
  }

  .react-calendar__navigation__arrow {
    color: black !important;
  }
  .react-calendar__navigation__arrow:hover {
    background-color: rgba(0, 123, 255, 0.3) !important;
  }

  .react-calendar__navigation__label {
    color: black !important;
  }
  .react-calendar__navigation__label:hover {
    background-color: rgba(0, 123, 255, 0.3) !important;
  }

  .react-calendar__tile {
    color: black !important;
  }
  .react-calendar__tile:hover {
    background-color: rgba(0, 123, 255, 0.3) !important;
  }
}
